<template>
  <section>
    <b-row>
      <b-col sm="0" md="2" xl="4"> </b-col>

      <b-col sm="12" md="8" xl="4">
        <h3 class="mb-3">Registrazione Account</h3>

        <b-card>
          <validation-observer ref="validationSalePoint">
            <b-row>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="Indirizzo E-mail" rules="required|email">
                  <b-form-group label="Indirizzo E-mail">
                    <b-form-input v-model="email" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-group label="Nome">
                    <b-form-input v-model="name" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider #default="{ errors }" name="Cognome" rules="required">
                  <b-form-group label="Cognome">
                    <b-form-input v-model="surname" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider #default="{ errors }" name="azienda" rules="required">
                  <b-form-group label="Azienda">
                    <v-select :options="companies" v-model="company" :selectable="(option) => !option.disabled"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="id_store" rules="required">
                  <b-form-group label="ID Store">
                    <b-form-input  v-model="id_store" type="number" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>

          <b-button class="my-1" block variant="primary" @click="validationSalePoint">Registra</b-button>
        </b-card>
      </b-col>

      <b-col sm="0" md="2" xl="4"></b-col>
    </b-row>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { required, email } from "@validations";

import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import { Requests } from "@/api/requests.js";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  data: () => ({
    companies: [
      {
        value: null,
        label: "Seleziona...",
        disabled: true,
      },
      {
        value: "dodeca",
        label: "Dodecà",
      },      
      {
        value: "deco",
        label: "Decò",
      },
    ],

    email: "",
    name: "",
    surname: "",
    company: null,
    id_store: "",
  
  }),

  created() {
    localize("it");
  },

  methods: {

    formReset() {
      this.email = "";
      this.name = "";
      this.surname = "";
      this.company = null;
    },

    validationSalePoint() {
      return new Promise((resolve, reject) => {
        this.$refs.validationSalePoint.validate().then((success) => {
          if (success) {
            this.salePointRegister();
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    async salePointRegister() {
      let data = new FormData();

      data.append("user_email", this.email);
      data.append("user_name", this.name);
      data.append("user_surname", this.surname);
      data.append("user_companyname", this.company.value);
      data.append("id_store", this.id_store);

      try {
        const response = await Requests.salePointRegister(data);

        console.log(response);

        switch (response.register.success) {
          case "true": {
            this.$swal.fire({
              icon: "success",
              title: "Registrazione Effettuata",
              text: response.register.feedback,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$refs.validationSalePoint.reset();
            this.formReset();
            break;
          }

          case "false": {
            this.$swal.fire({
              icon: "error",
              title: "Errore",
              text: response.register.feedback,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            break;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
