var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[_c('b-col',{attrs:{"sm":"0","md":"2","xl":"4"}}),_c('b-col',{attrs:{"sm":"12","md":"8","xl":"4"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Registrazione Account")]),_c('b-card',[_c('validation-observer',{ref:"validationSalePoint"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Indirizzo E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indirizzo E-mail"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cognome"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"azienda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Azienda"}},[_c('v-select',{attrs:{"options":_vm.companies,"selectable":function (option) { return !option.disabled; },"state":errors.length > 0 ? false : null},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"id_store","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ID Store"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.id_store),callback:function ($$v) {_vm.id_store=$$v},expression:"id_store"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-button',{staticClass:"my-1",attrs:{"block":"","variant":"primary"},on:{"click":_vm.validationSalePoint}},[_vm._v("Registra")])],1)],1),_c('b-col',{attrs:{"sm":"0","md":"2","xl":"4"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }